import { css } from '@emotion/core'
import React from 'react'

import { Container, EmailLink, Layout, Link, Section, SEO } from '../components'
import { makeContentStyle, textColumnStyle } from '../styles'
import makePath from '../utils/makePath'

const style = {
  inlineNav: css`
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      margin-right: 1em;
      display: inline-block;
    }
    a {
      display: inline-block;
      margin-bottom: 0.3em;
    }
  `
}

export const SupportPage = (props: any) => {
  const currPath = props.location.pathname
  const pageColor = 'grey'
  // This following line disables formatting linting
  /* eslint-disable prettier/prettier */
  return (
    <Layout currPath={currPath} pageColor={pageColor}>
      <SEO title="Mach mit!" />
      <Section index={0} color={pageColor} renderBg={true}>
        <Container>
          <div css={[textColumnStyle, makeContentStyle(pageColor)]}>
            <nav css={style.inlineNav}>
              <ul>
                <li>
                  <a href="#material">Material bestellen</a>
                </li>
                <li>
                  <a href="#mitglied-werden">Mitglied werden</a>
                </li>
                <li>
                  <a href="#spenden">Spenden</a>
                </li>
                <li>
                  <a href="#zeitungs-abo">Zeitung abonnieren</a>
                </li>
              </ul>
            </nav>
            <h1>Mitmachen</h1>
            <Link
              id="material"
              href={makePath('soli-verbindet-material.zip')}
              download
              title="»Solidarität verbindet«-Kampagne: Material-Paket"
              noPadding
            >
              <img src={makePath('media/material.jpg')} alt="Material-Paket herunterladen!" />
            </Link>
            <h2>Unterstützt die Rote Hilfe und bestellt Kampagnenmaterial im Literaturvertrieb!</h2>
            <p>
              Es gibt einen Kampagnenflyer sowie 7 verschiedene Motive an Plakaten und Stickern, in
              denen jeweils unsere Arbeit kurz und knackig erläutert wird. Streut das Material in
              euren Lieblingslocations, in eurem Freundeskreis, auf Demos und Veranstaltungen.
              Bestellungen einfach per Mail an <EmailLink email="literaturvertrieb@rote-hilfe.de" />
            </p>

            <h2 id="mitglied-werden">Rote Hilfe-Mitglied werden</h2>
            <p>
              Eine passive Mitgliedschaft in der RH hilft uns sehr, da wir nur durch die
              regelmäßigen Beitragszahlungen unserer Mitglieder kontinuierliche
              Antirepressionsarbeit ermöglichen können und somit nicht auf oft ein schwankendes und
              schwer vorhersehbares Spendenaufkommen angewiesen sind. Wenn du Mitglied werden
              möchtest, dann bitte folgendes Beitrittsformular ausdrucken und an die angegebene
              Adresse einsenden. Wie wir im Rahmen der Mitgliederverwaltung mit deinen Daten umgehen
              erfährst du <a href="https://rote-hilfe.de/images/pdf/Art13-mitglied.pdf">hier</a>.
              Mitglieder erhalten die 4x im Jahr erscheinende Rote Hilfe Zeitung kostenlos im Abo.
              Wenn Du außerdem in der Roten Hilfe aktiv mitarbeiten möchtest, melde Dich bitte beim
              Bundesvorstand oder einer Ortsgruppe.
            </p>
            <p>
              <strong>
                Achtung: Das Formular muss aus rechtlichen Gründen unterschrieben und per Post/Fax
                eingesendet werden! Es ist nicht möglich per Mail einzutreten.
              </strong>
            </p>
            <p>
              Rote Hilfe e.V.
              <br />
              Bundesgeschäftsstelle
              <br />
              Postfach 3255
              <br />
              37022 Göttingen
              <br />
              Fax: (05 51) 7 70 80 09
            </p>
            <p>
              <Link
                href="https://rote-hilfe.de/downloads1/category/1-flyer-rote-hilfe-e-v-mitglied-werden?download=1:rote-hilfe-ev-mitglied-werden"
                icon="file"
              >
                Infoflyer mit Beitrittsformular
              </Link>
            </p>
            <p>
              <Link
                href="https://rote-hilfe.de/downloads1/category/11-beitrittsformular?download=15:formular-beitrittserklaerung-erhoehung-des-mitgliedsbeitrag"
                icon="file"
              >
                Beitrittsformular
              </Link>
            </p>

            <h2 id="spenden">Spenden</h2>
            <p>
              Um die Arbeit der Roten Hilfe e.V. zu finanzieren, sind wir auf Mitglieder und Spenden
              angewiesen. Wir freuen uns über jede auch noch so kleine Spende. Solltet ihr keinen
              Verwendungszweck angeben, werden wir selbst entscheiden für welchen Bereich unserer
              Arbeit gerade am nötigsten Geld gebraucht wird.
            </p>
            <h3>Zentrales Spenden- und Beitragskonto</h3>
            <p>
              Rote Hilfe e.V.
              <br />
              <br />
              Kontonummer: 56036239
              <br />
              BLZ: 260 500 01
              <br />
              bei der Sparkasse Göttingen
              <br />
              <br />
              IBAN: DE25 2605 0001 0056 0362 39
              <br />
              BIC: NOLADE21GOE
            </p>

            <h2 id="zeitungs-abo">Abo der Roten Hilfe Zeitung</h2>
            <p>
              »Die Rote Hilfe« erscheint viermal im Jahr und kostet 10 € im Abonnement; das
              Einzelheft 2€.
            </p>
            <p>
              Ein Abo kann unter literaturvertrieb@rote-hilfe.de bestellt werden. Gefangene,
              Infoläden und linke Projekte erhalten das Heft kostenlos. RA-Kanzleien können zwei
              kostenlose Exemplare pro Ausgabe erhalten.
            </p>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default SupportPage
